import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const CloudForceHr = () => {
  const headingbanner = {
    title: `CloudForceHr`,
    content: `A SaaS Based HRMS Software`,
  };

  const data = {
    images: ["cloudforce.webp", "cloudforce-2.webp"],
    firstAlt: "UI UX screen to configure employee data to HRMS Software",
    firstTitle: "Configuration Screen",
    secondAlt: "UI Screen to take bulk actions on employee’s data",
    secondTitle: "Bulk Action on Employees Data",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: ["Digital Transformation", "UI UX Designing", "Angular Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The main challenge we faced was to simplify the user flows and navigation of existing legacy enterprise human resource software. `,
          `We were tasked to transform this platform into a dynamic portal and SaaS product that could effectively support complex organizational structures. `,
          `We had to make onboarding and training easier for users, ensuring they could quickly understand and utilize the software's functionalities while also ensuring that the platform supported local language, allowing organizations from various regions to seamlessly use the platform. `,
          `Simplifying workflows and streamlining processes were also essential aspects of our redesign efforts, ensuring a more efficient and effective experience for users. `,
          `Lastly, we strived to create a dynamic role-based portal and app, tailoring the software to accommodate the specific needs and responsibilities of different users within an organization.`
        ],
      },
      {
        title: "Solution",
        content: [
          `Our main objective was to overhaul and modernise the HRMS legacy software, transforming it into a state-of-the-art SaaS product with dynamic functionality.`,
          `This involved building a multi-platform design system that would seamlessly work across different devices and operating systems.`,
          `We also aimed to simplify the information architecture and forms, making it more user-friendly and intuitive for employees and administrators alike.`,
          `Another critical aspect of our solution was creating a dynamic layout that supports role-based portals and applications.`,
          `This ensures that users only have access to the features and functionalities that are relevant to their specific role within the organization, enhancing efficiency and security.`,
          `Furthermore, we wanted to make the system adaptable to local language translation to cater to a diverse user base. By allowing for easy translation, we enable organizations to provide a personalized and localized experience to their employees, regardless of their language preferences.`
        ],
      },
    ],
    image: ["cloudforce-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Configuration",
    para: [
      `Once the user login into this software, they will first land on this configuration page to configure their organisation information such as company details, payroll management, time management, etc. `,
    ],
    content: {
      imageAlt: 'UI to configure company details',
      imageTitle: 'Configuration',
      image: ["cloudforce-projectone.webp"],
      text: `This helps in offering customised solutions as per the organisational needs.`,
    },
    content2: {
      imageAlt: 'UI Screen to create custom paycode',
      imageTitle: 'Create Custom Paycode',
      image: ["cloudforce-projectone-2.webp"],
      text: "Next up, the user of this software has to fill up their company information and define their working period. We also provided information to import the data and take bulk actions. It assists users to improve their productivity by saving time on manually adding all the information.",
    },
  };

  const projectData2 = {
    title: `Information`,
    para: [
      `We also created a mobile version for the employees wherein they can seamlessly manage their attendance, leaves, etc. and look into the total working hours to keep track of their performance.`,
    ],
    content: {
      image: ["cloudforce-projecttwo.webp"],
      imageAlt: 'Mobile Responsive UI Screen of Employee Profile',
      imageTitle: 'Employee Profile',
    },
  };

  const projectData3 = {
    title: "Employee Information",
    para: [
      `The following screen provides employees’ information along with their pay code for improved efficiency.`,
    ],
    content: {
      image: [
        "cloudforce-projectthree.webp",
        "cloudforce-projectthree-2.webp",
        "cloudforce-projectthree-3.webp",
        "cloudforce-projectthree-4.webp",
        "cloudforce-projectthree-5.webp",
        "cloudforce-projectthree-6.webp",
      ],
      firstAlt: 'UI screen to edit employee details',
      firstTitle: 'Employee Information',
      secondAlt: 'UI Screen to create custom paycode',
      secondTitle: 'Create Custom Paycode',
      thirdAlt: 'Login Screen',
      thirdTitle: 'Login Screen',
      fourthAlt: "Enter company's general information",
      fourthTitle: 'Company Information',
      fifthAlt: 'UI Screen to take bulk action on employee attendance',
      fifthTitle: 'Period Definition',
      sixthAlt: 'Mobile Responsive to check leave status, apply leave and check payslips',
      sixthTitle: 'Leave Insights',
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, our team successfully tackled the challenge of simplifying and modernizing the user flows and navigation of legacy enterprise human resource software.`,
        `Through the transformation into a dynamic portal and SaaS product, we were able to support complex organizational structures and provide a more efficient and effective experience for users.`,
        `Our multi-platform design system, role-based portals, and support for local language translation all contributed to creating a state-of-the-art HRMS solution that meets the specific needs of organizations from various regions.`,
        `The result is a user-friendly and intuitive software that enables organizations to streamline their processes and empower their employees with a personalized and localized experience.`
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={32} />
      </div>
    </Layout>
  );
};

export default CloudForceHr;

export const Head = () => (
  <Seo title="CloudforceHR - Octet Design Studio" description="We digitally transformed the legacy HRMS software and made it a SaaS product with our services on UI UX Designing and Angular Development." />
)